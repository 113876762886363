import { Container } from "~/components/ui/container";
import { MegaNav } from "~/components/meganav";
import AivenLogo from "~/images/logo-aiven.svg";
import AivenPrideLogo from "~/images/logo-aiven-pride.svg";
import { ButtonLink } from "~/components/ui/button";
import type { NavigationDocument } from "~/types/sanity-schema";
import { asLink } from "~/utils/sanity-helpers";
import { MobileMenu, SecondaryMobileMenu } from "~/components/mobilenav";
import { Link } from "~/components/ui/link";
import { Icon } from "~/components/ui/icons";
import { externalLinks } from "~/utils/external-links";
import { useSharedContent } from "~/hooks/localization";
import { Image } from "~/components/ui/image";
import { LanguageSelector } from "~/components/language-selector";
import { SearchButton } from "~/components/algolia-search/search";
import { removeTrailingSlash } from "~/utils/misc";
import { usePageAvailableLocales } from "~/hooks/common";

const currentMonth = new Date().getMonth();
const isJune = currentMonth === 5;

interface Props {
	lang: string;
	navigationData: NavigationDocument;
}

export function Navbar({ lang, navigationData }: Props) {
	const { t } = useSharedContent();

	const availableLocales = usePageAvailableLocales();
	const shouldShowLanguageSelector = availableLocales.length > 0;

	const isEngPage = lang === "en";

	function renderCTA() {
		const { primaryCTA, secondaryCTA } = navigationData;
		const primaryCTALink = asLink(primaryCTA);
		const secondaryCTALink = asLink(secondaryCTA);

		return (
			<div className="hidden flex-shrink-0 items-center gap-3 xl:flex">
				<ButtonLink
					variant="ghost"
					size="dense"
					to={externalLinks.consoleLogin}
					trackingPosition="menu"
					iconRight={
						<Icon name="external-link" color="primary" height="14" width="14" />
					}
				>
					{t("login")}
				</ButtonLink>
				{secondaryCTA && secondaryCTALink ? (
					<ButtonLink
						variant="secondary"
						size="dense"
						to={secondaryCTALink}
						trackingPosition="menu"
					>
						{secondaryCTA.title}
					</ButtonLink>
				) : null}
				{primaryCTA && primaryCTALink ? (
					<ButtonLink size="dense" to={primaryCTALink} trackingPosition="menu">
						{primaryCTA.title}
					</ButtonLink>
				) : null}
				{isEngPage ? <SearchButton /> : null}
				{shouldShowLanguageSelector ? (
					<div className="ml-3">
						<LanguageSelector iconOnly={true} />
					</div>
				) : null}
			</div>
		);
	}

	function renderPrimaryNav() {
		return (
			<nav
				aria-label="Site"
				className="border-stroke bg-primary h-mobile-nav w-full border-b xl:h-nav"
			>
				<Container noPadding className="flex h-full justify-between gap-3 px-6">
					<div className="flex w-full items-center justify-between gap-6 lg:px-0 xl:w-auto 2xl:gap-7">
						<div className="flex items-center justify-center">
							<Link
								to={removeTrailingSlash(
									//getLocalePath("/", lang)
									"/" //Temporary: always redirect back to English homepage
								)}
								prefetch="intent"
								trackingText="Aiven home"
								trackingPosition="logo"
								className="min-w-[95px]"
								aria-label="Go to Aiven homepage"
							>
								<Image
									loading="eager"
									width="140px"
									height="47px"
									src={isJune ? AivenPrideLogo : AivenLogo}
									alt="Aiven Logo"
								/>
							</Link>
						</div>
						<>
							<div className="hidden xl:flex">
								<MegaNav navigationData={navigationData} />
							</div>

							<div className="flex place-items-center xl:hidden">
								<MobileMenu navigationData={navigationData} />
							</div>
						</>
					</div>
					{renderCTA()}
				</Container>
			</nav>
		);
	}

	function renderSecondaryNav() {
		return (
			<nav
				aria-label="Secondary"
				className="border-stroke bg-secondary h-nav-secondary w-full border-b"
			>
				<Container noPadding className="flex h-full justify-between">
					<div className="hidden px-5 xl:flex">
						<MegaNav variant="secondary" navigationData={navigationData} />
					</div>

					<div className="block w-full xl:hidden">
						<SecondaryMobileMenu navigationData={navigationData} />
					</div>
				</Container>
			</nav>
		);
	}

	return (
		<>
			{navigationData.variant === "primary"
				? renderPrimaryNav()
				: renderSecondaryNav()}
		</>
	);
}

export function SimpleNavbar() {
	return (
		<nav
			aria-label="Site"
			className="border-stroke bg-primary h-mobile-nav w-full border-b xl:h-nav"
		>
			<Container noPadding className="flex h-full items-center px-6">
				<Link
					to="/"
					prefetch="intent"
					trackingText="Aiven home"
					trackingPosition="logo"
					className="min-w-[95px]"
					aria-label="Go to Aiven homepage"
				>
					<Image
						loading="eager"
						width="140px"
						height="47px"
						src={isJune ? AivenPrideLogo : AivenLogo}
						alt="Aiven Logo"
					/>
				</Link>
			</Container>
		</nav>
	);
}
