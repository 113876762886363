import clsx from "clsx";
// import type { Variants } from "framer-motion";
// import { motion, useReducedMotion } from "framer-motion";
import groupBy from "lodash/groupBy";
import { SubNavLinkGroup } from "./subnav-link-group";
import { Paragraph } from "../ui/typography";
import type { NavLinkGroup, Link } from "~/types/sanity-schema";
import { asLink, asText } from "~/utils/sanity-helpers";
import { Link as LinkComponent } from "~/components/ui/link";
import { useLocation } from "@remix-run/react";

type ButtonProps = JSX.IntrinsicElements["button"];
interface SubNavButtonProps extends ButtonProps {
	buttonName: string;
	onClick: () => void;
	open: boolean;
	active: boolean;
	variant: "primary" | "secondary";
}

function SubNavButton(props: SubNavButtonProps) {
	const { buttonName, onClick, id, open, variant, active } = props;

	const variants = {
		primary: "decoration-theme-primary underline-offset-8 hover:underline",
		secondary: "hover:underline underline-offset-8 decoration-theme-primary",
	};

	const openClasses = {
		primary: "decoration-theme-primary",

		secondary: "underline",
	};

	const activeClasses = {
		primary: "decoration-theme-primary underline underline-offset-8",
		secondary: "underline",
	};

	return (
		<button
			id={id}
			aria-expanded={props["aria-expanded"]}
			onClick={onClick}
			className={clsx(
				"group relative flex items-center px-3 py-4",
				variants[variant],
				open && openClasses[variant],
				active && activeClasses[variant]
			)}
		>
			<Paragraph as="span" size="button-small" color="current">
				{buttonName}
			</Paragraph>

			{/* Icon chevron-down */}
			<i
				className={clsx(
					"mb-2 ml-3 inline-block h-[7px] w-[7px] rotate-45 transform border-b-[1.5px] border-l-0 border-r-[1.5px] border-t-0 border-solid border-grey-40 group-hover:relative group-hover:top-2 group-hover:-rotate-[135deg]",
					variants[variant]
				)}
			></i>
		</button>
	);
}

interface Props {
	buttonName: string;
	open?: boolean;
	navLinkGroups?: Array<NavLinkGroup>;
	navSubmenuItems?: Array<Link>;
	id: string;
	onClick: (id: string) => void;
	onMouseEnter: (id: string) => void;
	onMouseLeave: (id: string) => void;
	variant?: "primary" | "secondary";
}

export function MegaNavSection({
	id,
	navLinkGroups,
	navSubmenuItems,
	buttonName,
	open = false,
	onClick,
	onMouseEnter,
	onMouseLeave,
	variant = "primary",
}: Props) {
	const location = useLocation();
	// const shouldReduceMotion = useReducedMotion();

	// const menuAnimationVariants: Variants = {
	// 	open: {
	// 		opacity: 1,
	// 		y: 0,
	// 		transition: {
	// 			duration: shouldReduceMotion ? 0 : 0.3,
	// 			ease: "easeInOut",
	// 		},
	// 		display: "block",
	// 	},
	// 	closed: {
	// 		opacity: 0,
	// 		transition: {
	// 			duration: 0.1,
	// 		},
	// 		transitionEnd: {
	// 			display: "none",
	// 			y: -12,
	// 		},
	// 	},
	// };

	function renderPrimarySubnav() {
		if (!navLinkGroups) {
			return null;
		}

		const linksGroupByColumn = groupBy(navLinkGroups, "group");
		const columns = Object.keys(linksGroupByColumn);

		const active = navLinkGroups
			.flatMap((group) => group.items)
			.some((item) => {
				const navLink = asLink(item?.navLink);

				if (!navLink) {
					return false;
				}

				return (
					navLink === location.pathname ||
					location.pathname.startsWith(`${navLink}/`)
				);
			});

		return (
			<>
				<SubNavButton
					variant={variant}
					open={open}
					active={active}
					id={id}
					aria-expanded={open}
					onClick={() => onClick(id)}
					buttonName={buttonName}
				/>
				<div
					role="region"
					aria-labelledby={id}
					className={clsx(
						"absolute left-0 top-full z-50",
						"filter-dropshadow",
						{
							hidden: !open,
						}
					)}
					// animate={open ? "open" : "closed"}
					// variants={menuAnimationVariants}
				>
					<div className="border-stroke bg-primary mt-2 max-h-mega-nav overflow-y-auto rounded-lg border">
						<div className="flex">
							{columns.sort().map((column, index) => (
								<div
									key={column}
									className={clsx("flex w-[400px] flex-col gap-6 p-6", {
										"bg-secondary": index === 0,
									})}
								>
									{linksGroupByColumn[column].map((group, index) => {
										return group.items ? (
											<SubNavLinkGroup
												key={index}
												headerText={asText(group.title)}
												items={group.items}
											/>
										) : null;
									})}
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}

	function renderSecondarySubnav() {
		if (!navSubmenuItems) {
			return null;
		}

		const isActive = navSubmenuItems.some((item) => {
			const navLink = asLink(item);

			if (!navLink) {
				return false;
			}

			return (
				navLink === location.pathname ||
				location.pathname.startsWith(`${navLink}/`)
			);
		});

		return (
			<div className="relative">
				<SubNavButton
					variant={variant}
					active={isActive}
					open={open}
					id={id}
					aria-expanded={open}
					onClick={() => onClick(id)}
					buttonName={buttonName}
				/>
				<div
					role="region"
					aria-labelledby={id}
					className={clsx(
						"absolute left-0 top-full z-50",
						"filter-dropshadow",
						{
							hidden: !open,
						}
					)}
				>
					<div className="border-stroke bg-primary mt-2 max-h-mega-nav overflow-y-scroll rounded-lg border">
						<div className="flex w-[400px] flex-col gap-3 p-6">
							{navSubmenuItems.map((item, index) => {
								const url = asLink(item);

								return url ? (
									<LinkComponent
										key={index}
										to={url}
										trackingText={item.title}
										trackingPosition="menu"
										className="hover:bg-secondary group rounded-lg p-3"
									>
										<Paragraph
											className="mb-1"
											color="current"
											fontWeight="font-medium"
											size="body-small"
										>
											{item.title}
										</Paragraph>
									</LinkComponent>
								) : null;
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			onMouseEnter={() => onMouseEnter(id)}
			onMouseLeave={() => onMouseLeave(id)}
			className="flex h-full items-center"
		>
			{variant === "primary" ? renderPrimarySubnav() : renderSecondarySubnav()}
		</div>
	);
}
